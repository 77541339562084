import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router, NavigationExtras} from '@angular/router';
import * as Types from '../../../app-setting/app-setting';
import {map, catchError} from 'rxjs/operators';
import {forkJoin, of, BehaviorSubject} from 'rxjs';
import {CommonSerive} from '../../../shared/services/common.service';
import {CallApiService} from '../../../configs/call-api.service';
import {Url} from "../../../configs/url";
import {Body} from "@angular/http/src/body";

let If_ALREADY_CALL_GET_ROLE: any = null;

@Injectable({
    providedIn: 'root'
})
export class MaterialService {
    language = localStorage.getItem('language') ? localStorage.getItem('language') : 'vi';

    constructor(private _http: HttpClient,
                private router: Router,
                private commonService: CommonSerive,
                private callApiService: CallApiService,) {
    }

    makeStatistic(body) {
        body.language = this.language;
        return this.callApiService.callApiPost(Url.MAKE_MATERIAL_STATISTIC, body);
    }

    search(body) {
        body.language = this.language;
        return this.callApiService.callApiPost(Url.GET_ALL_DM_MATERIAL, body)
    }

    create(body) {
        body.language = this.language;
        return this.callApiService.callApiPost(Url.CREATE_DM_MATERIAL, body);
    }

    update(body) {
        body.language = this.language;
        return this.callApiService.callApiPost(Url.UPDATE_DM_MATERIAL, body);
    }

    delete(body) {
        body.language = this.language;
        return this.callApiService.callApiPost(Url.DELETE_DM_MATERIAL, body);
    }

    getMaterialIO(body){
        body.language = this.language;
        return this.callApiService.callApiPost(Url.SEARCH_MATERIAL_IO, body);
    }

    createMaterialIO(body){
        body.language = this.language;
        return this.callApiService.callApiPost(Url.CREATE_MATERIAL_IO, body);
    }

    createMaterialIOForAssignmentUpdate(body){
        body.language = this.language;
        return this.callApiService.callApiPost(Url.CREATE_MATERIAL_IO_FOR_ASSIGNMENT_UPDATE, body);
    }

    getEmployee(body){
        return this.callApiService.callApiPost(Url.SEARCH_EMPLOYEE, body);
    }
    
    searchDMPlace(body){
        return this.callApiService.callApiPost(Url.SEARCH_DM_PLACE, body)
    }

    updateMaterialIODetail(body){
        return this.callApiService.callApiPost(Url.UPDATE_MATERIAL_IO_DETAIL, body)
    }

}